import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'

export default class extends Controller {
  static targets = ['list']
  static values = { updateUrl: String }

  connect(){
    console.log("Kanban controller connected")
    this._initializeSortable()
    this._highlightCardFromUrl()
  }

  _initializeSortable() {
    this.listTargets.forEach(function (target) {
      new Sortable(target, {
        group: "kanban",
        sort: false,
        animation: 150,
        draggable: ".card",
        scroll: true,
        scrollSpeed: 20,
        forceAutoScrollFallback: true,
        scrollSensitivity: 200,
        
        onEnd: function (event) {
          const updateUrl = event.item.dataset.updateUrl;
          const body = JSON.parse(event.to.dataset.updateBody)

          const headers = {
            Accept: "text/vnd.turbo-stream.html",
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content,
          };

          const requestOptions = {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(body),
          };

          fetch(updateUrl, requestOptions)
            .then((response) => response.text())
            .then((html) => Turbo.renderStreamMessage(html));
        },
      });
    });
  }

  _highlightCardFromUrl() {
    const params = new URLSearchParams(window.location.search)
    const cardId = params.get('card_id')
    
    
    if (cardId) {
      const card = document.getElementById(`card_${cardId}`)
 
      if (card) {
        card.setAttribute('data-selected', 'true')
        card.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }
}
