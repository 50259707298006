/**
 * Input Menu Controller
 *
 * This controller manages the input field and its associated menu interactions.
 * It handles keyboard navigation, accessibility attributes, and menu visibility.
 *
 * Usage:
 * ```html
 * <div data-controller="input-menu">
 *   <input
 *     type="text"
 *     data-input-menu-target="input"
 *     data-action="
 *       focus->input-menu#handleFocus
 *       keydown->input-menu#handleKeydown
 *     "
 *   >
 *
 *   <div role="menu" data-input-menu-target="menu">
 *     <div
 *       aria-role="menuitem"
 *       data-input-menu-target="menuItem"
 *       data-action="
 *         mouseover->input-menu#handleMouseover
 *         click->input-menu#handleMenuItemClick
 *       "
 *     >
 *       Menu Item
 *     </div>
 *   </div>
 *
 *   <div data-input-menu-target="optionPanel">
 *     Panel content...
 *   </div>
 * </div>
 * ```
 *
 * Targets:
 * - input: The text input field
 * - menu: The menu container
 * - menuItem: Individual menu items
 * - optionPanel: Panels that can be shown/hidden
 */

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "menu", "menuItem", "optionPanel"]

  connect() {
    this._setupInput()
  }

  // Public methods - called directly by Stimulus actions
  handleFocus() {
    this._showMenuIfNoSubpanel()
    this.inputTarget.setAttribute("aria-expanded", "true")
  }

  handleKeydown(event) {
    const handlers = {
      ArrowDown: () => this._handleArrowNavigation(1),
      ArrowUp: () => this._handleArrowNavigation(-1),
      Enter: () => this._handleEnterKey(),
      Escape: () => this._handleEscapeKey()
    }

    const handler = handlers[event.key]
    if (handler) {
      event.preventDefault()
      handler()
    }
  }

  handleMenuItemClick(event) {
    const category = event.currentTarget.dataset.category
    if (!category) {
      this.menuTarget.classList.add("hidden")
      return
    }

    const panel = this.optionPanelTargets.find(p => p.id === `option-${category}`)
    if (!panel) return

    this._showPanel(panel)
  }

  // Private methods
  _setupInput() {
    if (this.hasMenuTarget) {
      this.inputTarget.setAttribute("role", "combobox")
      this.inputTarget.setAttribute("aria-expanded", "false")
      this.menuTarget.setAttribute("role", "listbox")
      this._showMenuIfNoSubpanel()
    }
  }

  _handleArrowNavigation(direction) {
    const menuItems = this._getActiveMenuItems()
    const currentIndex = this._getCurrentSelectedIndex(menuItems)
    const newIndex = (currentIndex + direction + menuItems.length) % menuItems.length
    this._selectMenuItem(menuItems, newIndex)
  }

  _handleEnterKey() {
    const menuItems = this._getActiveMenuItems()
    if (this.hasSubpanelOpen()) { return }

    const selectedItem = menuItems.find(item =>
      item.getAttribute("aria-selected") === "true"
    )
    if (selectedItem) selectedItem.click()
  }

  _handleEscapeKey() {
    const conversationMenuController = this.application.getControllerForElementAndIdentifier(
      this.element,
      'conversation-menu'
    )

    if (conversationMenuController?.hasSubpanelOpen()) {
      const backButton = this.element.querySelector('[data-action*="conversation-menu#backToMenu"]')
      backButton?.click()
    }
  }

  _getActiveMenuItems() {
    if (!this.hasMenuTarget) return []

    const activePanel = this.hasSubpanelOpen()
      ? this.optionPanelTargets.find(p => !p.classList.contains("hidden"))
      : this.menuTarget

    return activePanel === this.menuTarget
      ? this.menuItemTargets
      : Array.from(activePanel.querySelectorAll('[aria-role="menuitem"]'))
  }

  _getCurrentSelectedIndex(menuItems) {
    return menuItems.findIndex(item =>
      item.getAttribute("aria-selected") === "true"
    )
  }

  _selectMenuItem(menuItems, index) {
    menuItems.forEach((item, i) => {
      item.setAttribute("aria-selected", i === index ? "true" : "false")
    })
  }

  _showMenuIfNoSubpanel() {
    if (this.hasMenuTarget && !this.hasSubpanelOpen()) {
      this.menuTarget.classList.remove("hidden")
    }
  }

  _showPanel(panel) {
    this.menuTarget.classList.add("hidden")
    this.optionPanelTargets.forEach(p => p.classList.add("hidden"))
    panel.classList.remove("hidden")
    this.inputTarget.focus()
    this.inputTarget.setAttribute("aria-expanded", "true")
  }

  hasSubpanelOpen() {
    return this.optionPanelTargets.some(panel =>
      !panel.classList.contains("hidden")
    )
  }
}
