import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selectAllButton"]

  selectAll(event) {
    event.preventDefault()
    
    // Get the local-select controller instance
    const localSelectController = this.application.getControllerForElementAndIdentifier(
      this.element,
      "local-select"
    )
    
    if (localSelectController) {
      localSelectController.selectAll()
    }
  }
} 