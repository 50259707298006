// This controller is used to load the assistant sidebar in the sandbox

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["frame"]

  loadConversation(url) {
    this.frameTarget.src = url
    this.frameTarget.reload()
  }

  connect() {
    window.addEventListener("assistant:loadConversation", (event) => {
      this.loadConversation(event.detail.url)
    })
  }

  disconnect() {
    window.removeEventListener("assistant:loadConversation", (event) => {
      this.loadConversation(event.detail.url)
    })
  }
}
