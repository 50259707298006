import { Controller } from "@hotwired/stimulus";

/**
* This controller is used by the assistant to trigger events when the stream is rendered
*
* _Disclaimer_: the implementation is a hack, the stream rendered contains a element with the action "notification"
* which is not a real action, but a way to trigger events
*
* Doesn't require any markup
**/

export default class extends Controller {
  connect() {
    window.addEventListener("turbo:before-stream-render", this._handleBeforeStreamRender.bind(this))
    window.addEventListener("redactor:current-clause-updated", this._handleCurrentClauseUpdated.bind(this))
  }

  disconnect() {
    window.removeEventListener("turbo:before-stream-render", this._handleBeforeStreamRender);
    window.removeEventListener("redactor:current-clause-updated", this._handleCurrentClauseUpdated);
  }

  // Private methods

  _handleBeforeStreamRender(event) {
    // This block goes first
    // Defines a custom render for the new action "notification"
    const fallbackToDefaultActions = event.detail.render
    event.detail.render = function (streamElement) {
      if (streamElement.action == "notification") {
        // Do nothing
      } else {
        fallbackToDefaultActions(streamElement)
      }
    }

    // Our custom events must contain a subject attribute
    if (!event.target.attributes.subject) { return }

    // Our custom events must have an action attribute equal to "notification"
    const eventAction = event.target.attributes.action.nodeValue
    if (eventAction != "notification") { return }

    // Our custom events must have a subject attribute equal to "turbo_stream_finished"
    const eventSubject = event.target.attributes.subject.nodeValue
    if (eventSubject != "turbo_stream_finished") { return }

    // TODO: not necessary
    const eventContext = event.target.attributes.context.nodeValue
    const eventTarget = event.target.attributes.target.nodeValue

    const targetId = event.target.getAttribute('target')
    const targetElement = document.getElementById(targetId)
    if (!targetElement) { return }

    const template = targetElement.querySelector('template')
    if (!template) { return }

    const redactorProposal = template.content.querySelector('redactor_proposal')
    if (!redactorProposal) { return }

    // Simulate receiving a proposal from the assistant
    document.dispatchEvent(new CustomEvent("assistant:proposal", {
      detail: {
        content: redactorProposal.innerHTML.trim()
      }
    }))
  }

  _handleCurrentClauseUpdated(event) {
    let element = document.getElementById("conversation_context_extra_attributes")
    if (element) {
      element.value = JSON.stringify({ clause_to_generate: event.detail.clauseTitle })
    }
  }
}
