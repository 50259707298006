import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "form", "resultsContainer"]
  static values = {
    showSpinner: { type: Boolean, default: true },
  }

  initialize() {
    this.searchTimeout = null;
  }

  refresh(event) {
    if (this.formTarget.checkValidity()){
      if (event.target.classList.contains("js-search-term") && this._isCharacter(event) && event.key !== "Enter") {
        let currentValue = event.target.value.trim();
        if (currentValue.length > 3) {
          clearTimeout(this.searchTimeout);
          this.searchTimeout = setTimeout(() => {
            this._maybeFireForm(currentValue, event)
          }, 1000);
        }
      } else if (event.target.classList.contains("js-search-term") && event.key === "Enter") {
        event.preventDefault();
        clearTimeout(this.searchTimeout);
        this._fireForm(event);
      } else if(!event.target.classList.contains("js-search-term")){
        this._fireForm(event);
      }
    }
  }

  // If the value is still the same after the timeout, we fire the form
  // Otherwise we don't because the value has been updated.
  _maybeFireForm(previousValue, event){
    if (previousValue === event.target.value) {
      this._fireForm(event);
    }
  }

  _fireForm(){
    const spinner = document.querySelector(".js-spinner-search");
    if (this.showSpinnerValue && spinner) {
      spinner.classList.remove('hidden');
    }

    if (this.hasResultsContainerTarget) {
      this.resultsContainerTarget.classList.add('hidden');
    }

    Rails.fire(this.formTarget, 'submit');
  }

  _isCharacter({ key, code }){
    return key === "Backspace" ||
    key === "Delete" ||
    key === " " ||
    /^[0-9]$/i.test(key) || // number & numpad keys
    /^[a-z]$/i.test(key) || // letter keys
    ["Semicolon", "Equal", "Comma", "Minus", "Period", "Slash", "Backquote", "BracketLeft", "Backslash", "BracketRight", "Quote"].includes(code) // ;=,-./`[\]' (in order)
  }

}
