import { Controller } from "@hotwired/stimulus"

/**
 * Discardable Items Controller
 * 
 * This controller manages a list of items that can be temporarily hidden (discarded) from a table.
 * Discarded items are persisted in localStorage and can be shown/hidden using a toggle switch.
 * 
 * Usage:
 * 1. Add the controller to your table container:
 *    <div data-controller="discardable-items" data-discardable-items-table-name-value="unique_table_name">
 * 
 * 2. Add the message container for discarded items count:
 *    <div data-discardable-items-target="hiddenItemsMessage">
 *      <span data-discardable-items-target="counter"></span>
 *    </div>
 * 
 * 3. For each row that can be discarded:
 *    <tr data-discardable-items-target="row" data-item-id="unique_id">
 * 
 * 4. Add discard and restore buttons:
 *    <button data-discardable-items-target="discardButton" data-action="click->discardable-items#discardItem">
 *    <button data-discardable-items-target="restoreButton" data-action="click->discardable-items#restoreItem">
 * 
 * Features:
 * - Discard items: Hide items from the table
 * - Show/Hide discarded: Toggle visibility of discarded items with different styling
 * - Restore items: Remove items from the discarded list
 * - Clear all: Remove all items from the discarded list
 * - Persistence: Discarded items are saved in localStorage per table
 * 
 * Targets:
 * - row: Table rows that can be discarded
 * - discardButton: Button to discard an item
 * - restoreButton: Button to restore a discarded item
 * - hiddenItemsMessage: Container for the discarded items count message
 * - counter: Element to display the count of discarded items
 * 
 * Values:
 * - tableName: Unique identifier for the table (for localStorage)
 * - discardedIds: Array of discarded item IDs
 * - provisionallyShown: Whether discarded items are currently visible
 */

export default class extends Controller {
  static targets = ["row", "discardButton", "restoreButton", "hiddenItemsMessage", "counter"]
  static values = {
    tableName: String,
    discardedIds: { type: Array, default: [] },
    provisionallyShown: { type: Boolean, default: false }
  }

  initialize() {
    this.discardedIdsValue = []
  }

  connect() {
    this._loadDiscardedIds()
    this._updateHiddenItemsMessage()
    this._hideDiscardedRows()
  }

  discardItem(event) {
    event.preventDefault()
    event.stopPropagation()
    
    const button = event.currentTarget
    const row = button.closest('[data-discardable-items-target="row"]')
    
    if (!row) return
    
    const itemId = row.dataset.itemId
    
    if (!this.discardedIdsValue.includes(itemId)) {
      this.discardedIdsValue = [...this.discardedIdsValue, itemId]
      this._saveDiscardedIds()
      
      if (this.provisionallyShownValue) {
        // If we're showing discarded items, update the row appearance
        row.classList.add('bg-gobyellow-100')
        const discardButton = row.querySelector('[data-discardable-items-target="discardButton"]')
        const restoreButton = row.querySelector('[data-discardable-items-target="restoreButton"]')
        if (discardButton) discardButton.classList.add('hidden')
        if (restoreButton) restoreButton.classList.remove('hidden')
      } else {
        // If we're not showing discarded items, hide the row
        row.classList.add('hidden')
      }
      
      this._updateHiddenItemsMessage()
    }
  }

  restoreItem(event) {
    event.preventDefault()
    event.stopPropagation()
    
    const button = event.currentTarget
    const row = button.closest('[data-discardable-items-target="row"]')
    
    if (!row) return
    
    const itemId = row.dataset.itemId
    
    // Remove from discarded list
    this.discardedIdsValue = this.discardedIdsValue.filter(id => id !== itemId)
    this._saveDiscardedIds()
    
    // Update row appearance
    row.classList.remove('bg-gobyellow-100')
    const discardButton = row.querySelector('[data-discardable-items-target="discardButton"]')
    const restoreButton = row.querySelector('[data-discardable-items-target="restoreButton"]')
    if (discardButton) discardButton.classList.remove('hidden')
    if (restoreButton) restoreButton.classList.add('hidden')
    
    this._updateHiddenItemsMessage()
  }

  toggleDiscardedItems(event) {
    const isChecked = event.target.checked
    this.provisionallyShownValue = isChecked
    
    this.rowTargets.forEach(row => {
      if (this.discardedIdsValue.includes(row.dataset.itemId)) {
        if (isChecked) {
          row.classList.remove('hidden')
          row.classList.add('bg-gobyellow-100')
          // Hide discard button and show restore button
          const discardButton = row.querySelector('[data-discardable-items-target="discardButton"]')
          const restoreButton = row.querySelector('[data-discardable-items-target="restoreButton"]')
          if (discardButton) discardButton.classList.add('hidden')
          if (restoreButton) restoreButton.classList.remove('hidden')
        } else {
          row.classList.add('hidden')
          row.classList.remove('bg-gobyellow-100')
          // Show discard button and hide restore button
          const discardButton = row.querySelector('[data-discardable-items-target="discardButton"]')
          const restoreButton = row.querySelector('[data-discardable-items-target="restoreButton"]')
          if (discardButton) discardButton.classList.remove('hidden')
          if (restoreButton) restoreButton.classList.add('hidden')
        }
      }
    })
  }

  clearDiscardedItems(event) {
    event.preventDefault()
    
    if (!confirm("Si aceptas, todos los elementos descartados que has definido se eliminarán")) {
      return
    }
    
    // Show all rows, remove highlight classes and show all discard buttons
    this.rowTargets.forEach(row => {
      row.classList.remove('hidden', 'bg-gobyellow-100')
      const discardButton = row.querySelector('[data-discardable-items-target="discardButton"]')
      const restoreButton = row.querySelector('[data-discardable-items-target="restoreButton"]')
      if (discardButton) discardButton.classList.remove('hidden')
      if (restoreButton) restoreButton.classList.add('hidden')
    })
    
    // Clear the discarded items
    this.discardedIdsValue = []
    this._saveDiscardedIds()
    
    // Reset UI state
    this.provisionallyShownValue = false
    // Uncheck the switch
    const switchInput = this.element.querySelector('input[type="checkbox"]')
    if (switchInput) switchInput.checked = false
    this._updateHiddenItemsMessage()
  }

  // Private methods

  _loadDiscardedIds() {
    const key = `discarded_items_${this.tableNameValue}`
    const saved = localStorage.getItem(key)
    if (saved) {
      try {
        this.discardedIdsValue = JSON.parse(saved)
      } catch (e) {
        this.discardedIdsValue = []
      }
    }
  }

  _saveDiscardedIds() {
    const key = `discarded_items_${this.tableNameValue}`
    localStorage.setItem(key, JSON.stringify(this.discardedIdsValue))
  }

  _hideDiscardedRows() {
    this.rowTargets.forEach(row => {
      if (this.discardedIdsValue.includes(row.dataset.itemId)) {
        row.classList.add('hidden')
      }
    })
  }

  _updateHiddenItemsMessage() {
    if (!this.hasHiddenItemsMessageTarget) return
    
    const hiddenCount = this.discardedIdsValue.length
    
    if (hiddenCount > 0) {
      // Update counters
      this.counterTargets.forEach(counter => {
        counter.textContent = hiddenCount
      })
      
      this.hiddenItemsMessageTarget.classList.remove('hidden')
    } else {
      this.hiddenItemsMessageTarget.classList.add('hidden')
    }
  }
} 