import { Controller } from "@hotwired/stimulus";

/**
* This controller is used to autofocus an input element and move cursor to the end if it's a text field
* @markup-example:

<input type="text" name="name" data-controller="autofocus" />
**/

export default class extends Controller {
  connect(){
    this.element.focus()
    
    // If it's an input element that can have text content
    if (this.element.tagName === 'INPUT' && 
        ['text', 'password', 'email', 'search', 'tel', 'url'].includes(this.element.type)) {
      const length = this.element.value.length
      this.element.setSelectionRange(length, length)
    }
  }
}
