import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static targets = [ "selectField" ]
  static values = {
    allowClear: Boolean,
    useDropdownInput: {
      type: Boolean,
      default: true
    }
  }

  tomSelect = null

  connect() {
    const defaultPlugins = this.useDropdownInputValue ? ["dropdown_input"] : []
    const selectPlugins = defaultPlugins.concat(this.allowClearValue ? ["clear_button"] : [])

    this.tomSelect = new TomSelect(this.selectFieldTarget, {
      plugins: selectPlugins,
      valueField: "value",
    })
  }

  selectAll() {
    if (this.tomSelect) {
      const availableOptions = Array.from(this.selectFieldTarget.options).map(option => option.value)
      this.tomSelect.setValue(availableOptions)
    }
  }
}
