import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static targets = [ "selectField" ]
  static values = {
    noResults: { type: String, default: "No hay resultados" },
    addItem: { type: String, default: "Añadir" },
    minLength: { type: Number, default: 2 }
  }

  connect() {
    new TomSelect(this.selectFieldTarget, {
      plugins: ["clear_button"],
      maxItems: null,
      valueField: "id",
      labelField: "name",
      searchField: ["name"],
      sortField: [{field: '$order'}, {field: '$score'}],
      create: (input, callback) => {
        const newItem = {
          id: `new#${input}`,
          name: input,
          label: input,
          value: `new#${input}`,
          text: input
        };
        callback(newItem);
      },
      createOnBlur: true,
      persist: false,
      allowEmptyOption: true,
      render: {
        no_results: (data, escape) => {
          return `<div class="no-results">${this.noResultsValue}</div>`;
        },
        option_create: (data, escape) => {
          return `<div class="create">${this.addItemValue} "<strong>${escape(data.input)}</strong>"...</div>`;
        }
      },
      createFilter: (input) => {
        return input.length >= this.minLengthValue;
      }
    })
  }
} 