import { Controller } from "@hotwired/stimulus";
import { debounce } from "../helpers";

export default class extends Controller {
  static targets = ["wrapper", "messages", "newContentButton", "topButton"]

  initialize() {
    this.isScrolledToBottom = true;
    this.isScrolledFromTop = false;
    
    this._debouncedScroll = debounce(() => this.scrollToBottomClick(), 500).bind(this);
    
    this.observer = new MutationObserver((mutationList) => {
      for (const mutation of mutationList) {
        if (mutation.type === "childList") {
          this._debouncedScroll();
          this._toggleScrollButtons();
        }
      }
    });
  }

  connect() {
    const config = { 
      childList: true,
      subtree: true,
      attributes: false
    };
    
    this.observer.observe(this.messagesTarget, config);
    
    this.wrapperTarget.addEventListener('scroll', () => this._handleScroll());
    this.scrollToBottomClick();
    this._handleScroll();
  }

  disconnect() {
    this.observer.disconnect();
  }

  _handleScroll() {
    const wrapper = this.wrapperTarget;
    const wasAtBottom = this.isScrolledToBottom;
    const wasScrolledFromTop = this.isScrolledFromTop;
    
    this.isScrolledToBottom = Math.abs(wrapper.scrollHeight - wrapper.clientHeight - wrapper.scrollTop) < 10;
    this.isScrolledFromTop = wrapper.scrollTop > 100;
    
    if (wasAtBottom !== this.isScrolledToBottom || wasScrolledFromTop !== this.isScrolledFromTop) {
      this._toggleScrollButtons();
    }
  }

  _toggleScrollButtons() {
    if (this.hasNewContentButtonTarget) {
      this.newContentButtonTarget.classList.toggle('opacity-0', this.isScrolledToBottom);
      this.newContentButtonTarget.classList.toggle('pointer-events-none', this.isScrolledToBottom);
      this.newContentButtonTarget.classList.toggle('opacity-100', !this.isScrolledToBottom);
      this.newContentButtonTarget.classList.toggle('pointer-events-auto', !this.isScrolledToBottom);
    }

    if (this.hasTopButtonTarget) {
      this.topButtonTarget.classList.toggle('opacity-0', !this.isScrolledFromTop);
      this.topButtonTarget.classList.toggle('pointer-events-none', !this.isScrolledFromTop);
      this.topButtonTarget.classList.toggle('opacity-100', this.isScrolledFromTop);
      this.topButtonTarget.classList.toggle('pointer-events-auto', this.isScrolledFromTop);
    }
  }

  scrollToBottomClick() {
    this.wrapperTarget?.scrollTo({
      top: this.wrapperTarget.scrollHeight,
      behavior: 'smooth'
    });
  }

  scrollToTopClick() {
    this.wrapperTarget?.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
}
